<template>
   <full-page ref="fullpage" :options="{}">
        <section class="intro section" id="intro">
            <div class="intro-inner">
                <div class="intro-action">
                    <h2 class="intro-title">
                        Платформа
                    </h2>
                    <router-link :to="{ name: auth ? 'profile': 'login' }" class="intro-btn"
                    >Увійти
                    </router-link>
                </div>
            </div>
            <video
                class="videoBG"
                poster="../assets/video.mp4"
                data-autoplay
                muted
                loop
                playsinline
            >
                <source src="../assets/video.mp4" type="video/mp4"/>
            </video>
            <div class="intro-mobile">
                <router-link to="/" class="intro-logo">
                    <img src="../assets/images/logo.svg" alt="logo">
                </router-link>

                <div>
                    <h1 class="intro-title">
                        Платформа
                    </h1>
                    <router-link :to="{ name: 'login' }">
                        <CustomButton label="Увійти"/>
                    </router-link>
                </div>
            </div>
        </section>

<!--        <section class="section-quote section" :data-muted="isMuted">-->
<!--            <div class="section-quote__wrap">-->
<!--                <div class="section-quote_top">-->
<!--                    <h3 class="section-quote__title">Головні цитати</h3>-->
<!--                </div>-->
<!--                <div class="video-container">-->
<!--                    <div class="video-wrapper">-->
<!--                        <iframe src="https://www.youtube.com/embed/b4qeysq2LjI" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="quotes quote-wrap">-->
<!--                    <h4 class="quotes-item">-->
<!--                        {{ quotes[0] }}-->
<!--                    </h4>-->

<!--                    <div class="quotes-footer">-->
<!--                        <button-->
<!--                            class="btn-muted"-->
<!--                            @click="toggleMute"-->
<!--                            v-bind:class="{'on': !clicked, 'off': clicked}"-->
<!--                            v-on:click="clicked = !clicked"-->
<!--                        >-->
<!--                        </button>-->
<!--                        <h5 class="quotes-author">Петро порошенко.</h5>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <video-->
<!--                class="videoBG second-video"-->
<!--                poster="../assets/images/poroshenko.jpg"-->
<!--                ref="video"-->
<!--                data-autoplay-->
<!--            >-->
<!--                <source src="../assets/main-quotes.mp4" type="video/mp4"/>-->
<!--            </video>-->
<!--        </section>-->

        <section class="success-section section section-success">
            <h3 class="succes-title-m">
                Успіхи на виборах / рейтинги
            </h3>
            <div class="success-wrapper">
                <div class="succes-slider">
                    <button @click.prevent="slidePrev" class="prev-btn"></button>
                    <button @click.prevent="slideNext" class="next-btn"></button>
                    <SuccessSlider ref="carousel"/>
                </div>
                <div class="success-inner">
                    <h5 class="success-title">
                        Успіхи <br/>
                        на виборах / рейтинги
                    </h5>
                </div>
            </div>
        </section>

        <About class="section"/>
        <footer class="footer m-footer">
            <div class="footer-inner">
                <div class="social-links">
                    <a
                        class="social-links__item"
                        href="https://www.youtube.com/channel/UCjKn9aUDrZNUow49t43pY5Q?app=desktop"
                        target="_blank"
                    >
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M13.456 0.092C14.415 0.146 14.886 0.288 15.32 1.06C15.772 1.831 16 3.159 16 5.498V5.501V5.506C16 7.834 15.772 9.172 15.321 9.935C14.887 10.707 14.416 10.847 13.457 10.912C12.498 10.967 10.089 11 8.002 11C5.911 11 3.501 10.967 2.543 10.911C1.586 10.846 1.115 10.706 0.677 9.934C0.23 9.171 0 7.833 0 5.505V5.503V5.5V5.497C0 3.159 0.23 1.831 0.677 1.06C1.115 0.287 1.587 0.146 2.544 0.091C3.501 0.027 5.911 0 8.002 0C10.089 0 12.498 0.027 13.456 0.092ZM11 5.5L6 2.5V8.5L11 5.5Z"/>
                        </svg>
                    </a>
                    <a
                        class="social-links__item"
                        href="https://t.me/PresidentPoroshenko"
                        target="_blank"
                    >
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.11245 8.58603L5.85476 12.2105C6.22344 12.2105 6.38312 12.0522 6.5746 11.862L8.30312 10.21L11.8848 12.833C12.5417 13.1991 13.0045 13.0063 13.1817 12.2287L15.5327 1.2124L15.5333 1.21175C15.7417 0.240719 15.1821 -0.138997 14.5421 0.0992178L0.723077 5.38993C-0.220047 5.75601 -0.205767 6.28177 0.562752 6.51999L4.09573 7.61889L12.3021 2.48396C12.6883 2.22822 13.0395 2.36972 12.7507 2.62547L6.11245 8.58603Z"/>
                        </svg>
                    </a>
                    <a
                        class="social-links__item"
                        href="https://m.facebook.com/EuropeanSolidarity.official/"
                        target="_blank"
                    >
                        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.15622 16H2.34372C2.08463 16 1.87497 15.7903 1.87497 15.5312V9.375H0.468719C0.209626 9.375 -3.05176e-05 9.16534 -3.05176e-05 8.90625V6.09375C-3.05176e-05 5.83466 0.209626 5.625 0.468719 5.625H1.87497V3.28125C1.87497 1.47216 3.34713 0 5.15622 0H7.96872C8.22781 0 8.43747 0.209656 8.43747 0.46875V3.28125C8.43747 3.54034 8.22781 3.75 7.96872 3.75H5.62497V5.625H7.96872C8.1065 5.625 8.23744 5.68544 8.32622 5.79072C8.41547 5.896 8.45394 6.03472 8.43103 6.17066L7.96228 8.98316C7.92475 9.20928 7.72928 9.375 7.49994 9.375H5.62494V15.5312C5.62497 15.7903 5.41531 16 5.15622 16Z"/>
                        </svg>
                    </a>
                    <a
                        class="social-links__item"
                        href="https://www.instagram.com/europeansolidarity.official/"
                        target="_blank"
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M11.6689 0H4.33105C1.94287 0 0 1.94287 0 4.33105V11.6691C0 14.0571 1.94287 16 4.33105 16H11.6691C14.0571 16 16 14.0571 16 11.6691V4.33105C16 1.94287 14.0571 0 11.6689 0ZM8 12.3749C5.58765 12.3749 3.62512 10.4124 3.62512 8C3.62512 5.58765 5.58765 3.62512 8 3.62512C10.4124 3.62512 12.3749 5.58765 12.3749 8C12.3749 10.4124 10.4124 12.3749 8 12.3749ZM11.1868 3.36414C11.1868 4.07703 11.7666 4.65686 12.4795 4.65686C13.1924 4.65686 13.7723 4.07703 13.7723 3.36414C13.7723 2.65125 13.1924 2.07129 12.4795 2.07129C11.7666 2.07129 11.1868 2.65125 11.1868 3.36414Z"/>
                            <path
                                d="M8.00001 5.33337C6.52967 5.33337 5.33334 6.52961 5.33334 8.00004C5.33334 9.47038 6.52967 10.6667 8.00001 10.6667C9.47044 10.6667 10.6667 9.47038 10.6667 8.00004C10.6667 6.52961 9.47044 5.33337 8.00001 5.33337Z"
                                fill="#FFC900"/>
                        </svg>
                    </a>
                    <a
                        class="social-links__item"
                        href="https://twitter.com/euro_solidarity"
                        target="_blank"
                    >
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16 1.83763C15.405 2.09709 14.771 2.26906 14.11 2.35257C14.79 1.94897 15.309 1.31475 15.553 0.550295C14.919 0.926059 14.219 1.19148 13.473 1.3396C12.871 0.70239 12.013 0.307739 11.077 0.307739C9.261 0.307739 7.799 1.77302 7.799 3.56932C7.799 3.82779 7.821 4.07631 7.875 4.3129C5.148 4.18069 2.735 2.88142 1.114 0.902201C0.831 1.3903 0.665 1.94897 0.665 2.55039C0.665 3.67967 1.25 4.68071 2.122 5.26026C1.595 5.25032 1.078 5.09822 0.64 4.85865C0.64 4.86859 0.64 4.88151 0.64 4.89444C0.64 6.47901 1.777 7.79517 3.268 8.09837C3.001 8.17093 2.71 8.20573 2.408 8.20573C2.198 8.20573 1.986 8.1938 1.787 8.15006C2.212 9.44137 3.418 10.3907 4.852 10.4215C3.736 11.2894 2.319 11.8123 0.785 11.8123C0.516 11.8123 0.258 11.8003 0 11.7675C1.453 12.699 3.175 13.2308 5.032 13.2308C11.068 13.2308 14.368 8.2604 14.368 3.95205C14.368 3.8079 14.363 3.66873 14.356 3.53056C15.007 3.07129 15.554 2.4977 16 1.83763Z"/>
                        </svg>
                    </a>
                </div>
                <a href="#intro" class="footer-logo">
                    <img src="../assets/images/footer-logo-m.svg" alt="logo">
                </a>
            </div>
        </footer>
    </full-page>
</template>

<script>
import SuccessSlider from "@/components/SuccessSlider";
import About from "@/components/About";
import CustomButton from "@/components/CustomButton";

export default {
    computed: {
        playing() {
            return !this.paused;
        },
        auth() {
            return !!Object.keys(this.$store.getters.identity).length;
        }
    },
    data() {
        return {
            quotes: [
                "Я ніколи не боявся Путіна",
                "Я зроблю все, щоб не допустити реваншу",
                "Миротворці мають бути присутніми усюди",
            ],
            isMuted: true,
            clicked: false
        };
    },
    components: {
        SuccessSlider,
        About,
        CustomButton
    },
    mounted() {
        window.setInterval(() => {
            this.pollPerson();
        }, 5000);
        if(window.outerWidth <= 768 ) {
            fullpage_api.destroy();
        }
    },
    methods: {
        toggleMute() {
            const video = this.$refs.video;
            video.muted = this.isMuted = !video.muted;
        },
        showPassword: function () {
            if (this.inputType === "password") {
                this.inputType = "text";
            } else if (this.inputType === "text") {
                this.inputType = "password";
            }
            return this.inputType;
        },
        pollPerson() {
            const first = this.quotes.shift();
            this.quotes = this.quotes.concat(first);
        },
        slidePrev() {
            this.$refs.carousel.slidePrev();
        },
        slideNext() {
            this.$refs.carousel.slideNext();
        },
    },
};
</script>

<style scoped lang="less">
.is-active {
    background-color: red;
}

.initial {
    background-color: purple;
}

video {
    width: 100%;
    height: auto;
    max-height: 100%;
}

.videoBG {
    position: absolute;
    z-index: -1;

    @media screen and (max-width: 1100px) {
        display: none;
    }
}

.videoBG2 {
    position: absolute;
    z-index: -1;
}

@media (min-aspect-ratio: 16/9) {
    .videoBG {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (max-aspect-ratio: 16/9) {
    .videoBG {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.intro-mobile {
    display: none;
}

.intro {
    width: 100%;
    height: 100vh;
    position: relative;
}

.intro-inner {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    padding-top: 100px;
    padding-right: 124px;
    z-index: 10;
    background: url(../assets/images/logo_mask.png) left center no-repeat;
    background-size: cover;
    background-position: center;
}

.intro-action {
    display: flex;
    align-items: center;
    position: absolute;
    right: 120px;
    top: 50%;
    transform: translateY(-50%);
}

.intro-title {
    max-width: 620px;
    font: 700 80px @secondFontFamily;
    text-transform: uppercase;
    margin-left: -20%;
    margin-right: 20%;
    font-size: 64px;
    font-weight: 900;
    font-stretch: normal;
    position: relative;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: 1.16px;
    left: 120px;
    color: #fff;
    @media (max-width: 1100px){
        font-size: 4vw;
    }
    @media (max-width: 1200px){
        font-size: 43px;
    }
    @media (max-width: 1400px){
        font-size: 4vw;
    }
    @media (min-width: 1630px){
        font-size: 5vw;
    }
    @media (min-width: 2000px){
        font-size: 98px;
    }
}

.intro-btn {
    height: 161px;
    width: 161px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 700 20px/1.2 @secondFontFamily;
    text-transform: uppercase;
    color: #fff;
    background: @yellowColor;
    box-shadow: 0 14px 14px -14px fade(@yellowColor, 35%);
    margin-left: 33px;
    text-decoration: none;
    transition: all 0.3s ease-in;
    cursor: pointer;
}

.intro-btn:hover {
    transform: scale(1.12);
}

.section-quote {
    height: 100vh;
    // background: #fff;
    color: #000;
    position: relative;

    &__wrap {
        width: 640px;
        height: 100vh;
        // background: #1a2744;
        // opacity: 0.6;
        background: rgba(10%, 15%, 27%, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 204px;
        padding-left: 80px;
    }

    //&__title {
    //    font-weight: bold;
    //    font-size: 60px;
    //    line-height: 80px;
    //    letter-spacing: -0.42px;
    //    color: #ffc900;
    //}
}

.quote-wrap {
    position: absolute;
    width: 830px;
    left: 80px;
    bottom: 80px;
}

.quotes {
    &-item {
        font-weight: bold;
        font-size: 86px;
        line-height: 110px;
        letter-spacing: -0.602px;
        color: #ffffff;
        position: relative;
        margin-bottom: 20px;
        padding-left: 120px;
        padding-top: 60px;

        &::before,
        &::after {
            position: absolute;
            content: "";
            width: 92.52px;
            height: 101.16px;
        }

        &::before {
            top: 0;
            left: 0;
            background: url(../assets/images/quote.svg) no-repeat center;
        }

        &::after {
            right: 0;
            bottom: -50px;
            background: url(../assets/images/quote.svg) no-repeat center;
            transform: rotate(-180deg);
        }
    }

    &-footer {
        display: flex;
        align-items: center;
    }

    &-author {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.098px;
        text-transform: uppercase;
        color: #ffffff;
    }
}


// .btn-muted {
//   width: 64px;
//   height: 64px;
//   border-radius: 50%;
//   background: #ffc900;
//   margin-right: 64px;
// }

.on {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #ffc900;
    background-image: url(../assets/images/sound.svg);
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 64px;
}

.off {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #ffc900;
    background-image: url(../assets/images/muted.svg);
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 64px;
}


.success-slider {
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
}

.prev-btn,
.next-btn {
    position: absolute;
    width: 46px;
    height: 154px;
    top: 50%;
    z-index: 9999;
}

.next-btn {
    right: 0;
    background: url(../assets/images/btn-prev.svg) no-repeat;
    transform: translateY(-50%) rotate(180deg);
}

.prev-btn {
    left: 0;
    transform: translateY(-50%);
    background: url(../assets/images/btn-prev.svg) no-repeat;
}

.success-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.succes-slider {
    // min-width: 1280px;
    width: 70%;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
}

.success-inner {
    // max-width: 640px;
    width: 30%;
    height: 100vh;
    background: #1a2744 url(../assets/images/logo-success.svg) no-repeat left -60px
    top 80px;
    padding: 80px;
    display: flex;
    align-items: flex-end;
}

.success-title {
    font: 700 42px/1.52 @secondFontFamily;
    letter-spacing: -.29px;
    color: @yellowColor;
}

.lofo-p {
    width: 540px;
    height: 335px;
}

.m-footer {
    display: none;
}
.succes-title-m ,
 .second-video-m {
    display: none;
}

.section-quote_top {
    display: none;
}
.video-container {
    display: none;
}

@media screen and (max-width: 1920px) {
    .succes-slider {
        // max-width: 1280px;
        width: 70%;
    }

    .success-inner {
        // max-width: 640px;
        width: 30%;
    }
}

@media screen and (max-width: 1700px) {
    .intro-title {
        max-width: 620px;
        font-weight: bold;
        //font-size: 75px;
        line-height: 85px;
    }
}

@media screen and (max-width: 1700px) {
    .intro-title {
        max-width: 620px;
        font-weight: bold;
        //font-size: 65px;
        line-height: 75px;
    }
}

@media screen and (max-width: 1440px) {
    //.intro-title {
    //    font-size: 45px;
    //    line-height: 55px;
    //}

    .about-right {
        max-width: 70%;
        padding: 120px 40px 40px 40px;
    }

    .about-inner {
        padding: 40px;
    }

    .quote-wrap {
        width: 550px;
        left: 160px;
    }

    .quotes-item {
        font-size: 50px;
        line-height: 60px;
    }

    .succes-slider {
        max-width: 70%;
    }

    .success-inner {
        padding: 30px;
        max-width: 30%;
    }

    .success-title {
        font-size: 45px;
        line-height: 55px;
    }

    .section-quote__wrap {
        padding-top: 150px;
    }

    .quotes-item::before,
    .quotes-item::after {
        width: 60px;
        height: 70px;
        background-size: contain;
    }
}





@media screen and (max-width: 1100px) {
    .intro {
        background: url(../assets/images/intro-bg-m.jpg) no-repeat center center;
        background-size: cover;

        video {
            display: none;
        }

        &-action {
            display: none;
        }

        &-inner {
            display: none;
        }
        &-title {
            font-size: 40px;
            letter-spacing: -0.28px;
            text-align: center;
            max-width: 100%;
            margin: 0 auto 40px auto;
            left: auto;
        }

        &-logo {
            margin-bottom: 280px;
            display: block;

            @media (max-width: 480px) {
                margin-bottom: auto;
            }
        }

        &-mobile {
            display: block;
            padding: 80px 20px 80px 20px;
            text-align: center;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: 480px) {
                display: flex;
                height: 100vh;
            }
        }

        .custom-button {
            width: 180px;
            height: 46px;
            font-size: 16px;
            line-height: 20px;
        }

        .custom-button:after {
            margin-left: -70px;
            width: 120px;
            height: 36px;
            top: 15%;
        }
    }
    .section-quote {
        // display: none;
    }
    .section-quote__wrap {
        position: relative;
        padding: 0 0 40px 0;
        width: 100%;
        background-color: #1a2744;
        height: auto;
    }
    .second-video {
        display: none;
    }
    .second-video-m {
        display: block;
        position: absolute;
        left: 20px;
        right: 20px;
        width: 100%;
        height: auto;
        @media screen and (max-width: 480px) {
            max-width: 420px;
            width: 100%;
        }
        @media screen and (max-width: 360px) {
            max-width: 320px;
        }
    }
    .video-container {
        display: block;
        padding: 0 20px;
        transform: translateY(-110px);
    }

    .video-wrapper {
        position: relative;
        padding-bottom: 74.25%; /* 4:3 ratio */
        padding-top: 30px; /* IE6 workaround*/
        height: 0;
        overflow: hidden;
    }

    .video-wrapper-narrow {
        padding-bottom: 40%;
    }

    iframe,object,embed,video,.videoWrapper,.video-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-js, img.vjs-poster {
        width: 100% !important;
        height: 100% !important; //these need !important because IE wants to set height/width to 100px
        max-width: 100%;
    }


    .quotes-footer {
        padding: 0 20px;
    }

    .section-quote_top {
        display: block;
        background: #fff;
        padding: 20px 20px 130px 20px;
        position: relative;
    }
    .quotes-footer button {
        display: none;
    }
    .quotes-item {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.168px;
        padding-top: 0px;
        padding-left: 70px;
        padding-right: 70px;
    }
    .section-quote__title {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.21px;
        margin-bottom: 20px;
    }

    .quote-wrap {
        position: relative;
        width: 100%;
        left: auto;
        bottom: auto;
    }

    .quotes-item:before,
    .quotes-item:after {
        width: 30px;
        height: 33px;
    }
    .quotes-item:before {
        left: 20px;
    }
    .quotes-item:after {
        right: 20px;
    }


    .success {
        &-wrapper {
            flex-wrap: wrap;
        }
        &-inner {
            display: none;
        }
    }

    .succes-slider {
        max-width: 100%;
        width: 100%;
        height: auto !important;
    }

    .succes-slider2 {
        height: auto !important;
    }

    .succes-title-m {
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.21px;
        color: #FFC900;
        padding: 20px;
        display: block;
        margin-bottom: 70px;
    }




    .m-footer {
        display: block;
    }

    .footer {
        background: #fff;
        padding: 20px;

        &-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .social-links {
            margin-right: 20px;
            display: flex;
            align-items: center;
        }

        .social-links__item {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                fill: #ffc900;;
            }
            &:hover {
                box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
                background-color: #ffc900;
                svg {
                    fill: #fff;;
                }
            }
        }
    }

    .prev-btn,
    .next-btn {
        width: 20px;
        height: 84px;
        background-size: contain;
    }


}

@media screen and (max-width: 480px)  {
   &-mobile {
        display: block;
        padding: 60px 20px 60px 20px;
        text-align: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .section-success {
        height: 100vh !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


}


@media screen and (max-width: 360px) {
    .footer-inner {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
}

</style>

<style>
@media screen and (max-width: 1100px)  {
    .fp-tableCell,
    .fp-section  {
        height: auto !important;
    }
}
</style>
