<template>
    <section class="about">
        <div class="about-wrapper">
            <div class="about-inner">
                <h3 class="about-title">Про проект</h3>
                <p class="about-text">
                    ЄС Платформа - інновація в українській політиці
                </p>
                <p class="about-text">
                    Партійці та депутати «Європейської Солідарності» по всій країні завдяки партійному квитку мають доступ до віртуального хабу ЄС Платформи.
                </p>
                <p class="about-text">
                    Тут можна шукати однодумців, створювати спільні проекти і підтримувати ініціативи інших, бути на зв‘язку з партією 24/7 та разом, спільним фронтом крокувати до європейського майбутнього України!
                </p>
                <div class="about-text">
                    Позиція партія, політична освіта, важливі подіі у тебе в телефоні чи на комп’ютері. Ділись з нами своїми враженнями, допомогай піднімати важливі політичні питання у соціальних мережах та залучай своїх друзів до активної участі в політичному житті країни.
                </div>
                <div class="about-text">
                    Вперед, натискай «увійти» і відкривай віртуальний хаб ЄС Платформи!
                </div>
                <div class="social-links">
                    <a
                        class="social-links__item"
                        href="https://www.youtube.com/channel/UCjKn9aUDrZNUow49t43pY5Q?app=desktop"
                        target="_blank"
                    >
                        <svg width="18" height="13" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M25.23 0.1725C27.0281 0.27375 27.9113 0.54 28.725 1.9875C29.5725 3.43313 30 5.92313 30 10.3088V10.3144V10.3237C30 14.6887 29.5725 17.1975 28.7269 18.6281C27.9131 20.0756 27.03 20.3381 25.2319 20.46C23.4338 20.5631 18.9169 20.625 15.0037 20.625C11.0831 20.625 6.56437 20.5631 4.76813 20.4581C2.97375 20.3362 2.09063 20.0737 1.26937 18.6262C0.43125 17.1956 0 14.6869 0 10.3219V10.3181V10.3125V10.3069C0 5.92313 0.43125 3.43313 1.26937 1.9875C2.09063 0.538125 2.97563 0.27375 4.77 0.170625C6.56437 0.050625 11.0831 0 15.0037 0C18.9169 0 23.4338 0.050625 25.23 0.1725ZM20.625 10.3125L11.25 4.6875V15.9375L20.625 10.3125Z"/>
                        </svg>
                    </a>
                    <a
                        class="social-links__item"
                        href="https://t.me/PresidentPoroshenko"
                        target="_blank"
                    >
                        <svg width="18" height="15" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.0788 15.5622L10.6118 22.1316C11.28 22.1316 11.5694 21.8446 11.9165 21.4999L15.0494 18.5058L21.5412 23.2599C22.7318 23.9234 23.5706 23.574 23.8918 22.1646L28.1529 2.19752L28.1541 2.19634C28.5318 0.436341 27.5176 -0.251894 26.3576 0.17987L1.31058 9.76928C-0.398828 10.4328 -0.372946 11.3858 1.02 11.8175L7.42352 13.8093L22.2976 4.50222C22.9976 4.03869 23.6341 4.29516 23.1106 4.75869L11.0788 15.5622Z"/>
                        </svg>
                    </a>
                    <a
                        class="social-links__item"
                        href="https://m.facebook.com/EuropeanSolidarity.official/"
                        target="_blank"
                    >
                        <svg width="10" height="18" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.37885 26H3.80853C3.38751 26 3.04681 25.6593 3.04681 25.2383V15.2344H0.761658C0.34063 15.2344 -6.10352e-05 14.8937 -6.10352e-05 14.4727V9.90234C-6.10352e-05 9.48132 0.34063 9.14062 0.761658 9.14062H3.04681V5.33203C3.04681 2.39225 5.43907 0 8.37885 0H12.9492C13.3702 0 13.7109 0.340691 13.7109 0.761719V5.33203C13.7109 5.75306 13.3702 6.09375 12.9492 6.09375H9.14056V9.14062H12.9492C13.1731 9.14062 13.3858 9.23884 13.5301 9.40992C13.6751 9.581 13.7376 9.80642 13.7004 10.0273L12.9387 14.5976C12.8777 14.9651 12.5601 15.2344 12.1874 15.2344H9.14051V25.2383C9.14056 25.6593 8.79987 26 8.37885 26Z"/>
                        </svg>
                    </a>
                    <a
                        class="social-links__item"
                        href="https://www.instagram.com/europeansolidarity.official/"
                        target="_blank"
                    >
                        <svg width="16" height="16" viewBox="0 0 512.00096 512.00096"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875 138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm-117.40625 395.996094c-77.195312 0-139.996094-62.800782-139.996094-139.996094s62.800782-139.996094 139.996094-139.996094 139.996094 62.800782 139.996094 139.996094-62.800782 139.996094-139.996094 139.996094zm143.34375-246.976563c-22.8125 0-41.367188-18.554687-41.367188-41.367187s18.554688-41.371094 41.367188-41.371094 41.371094 18.558594 41.371094 41.371094-18.558594 41.367187-41.371094 41.367187zm0 0"/>
                            <path
                                d="m256 146.019531c-60.640625 0-109.980469 49.335938-109.980469 109.980469 0 60.640625 49.339844 109.980469 109.980469 109.980469 60.644531 0 109.980469-49.339844 109.980469-109.980469 0-60.644531-49.335938-109.980469-109.980469-109.980469zm0 0"/>
                            <path
                                d="m399.34375 96.300781c-6.257812 0-11.351562 5.09375-11.351562 11.351563 0 6.257812 5.09375 11.351562 11.351562 11.351562 6.261719 0 11.355469-5.089844 11.355469-11.351562 0-6.261719-5.09375-11.351563-11.355469-11.351563zm0 0"/>
                        </svg>
                    </a>
                    <a
                        class="social-links__item"
                        href="https://twitter.com/euro_solidarity"
                        target="_blank"
                    >
                        <svg width="19" height="15" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g><path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
              c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
              c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
              c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
              c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
              c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
              C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
              C480.224,136.96,497.728,118.496,512,97.248z"/></g></g>
                            </svg>
                    </a>

                    <div class="copy">
                        Developed by <a href="https://3k.group" target="_blank">3k.group</a>
                    </div>
                </div>
            </div>
            <div class="about-right">
                <div class="about-img">
                    <img src="../assets/images/about-img.png" alt="people"/>
                </div>
                <a href="#" class="footer-logo">
                    <img src="../assets/images/footer-logo.svg" alt="logo"/>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    mounted() {
        if (window.outerWidth <= 768) {
            fullpage_api.destroy();
        }
    },
}
</script>

<style lang="less" scoped>
.about {
    height: 100vh;
    background: #fff;

    &-wrapper {
        display: flex;
    }
    .copy{
        font: 12px/1.33 @defaultFontFamily;
        position: relative;
        color: #9da3ae;
        top: -12px;
        flex: 1 1 auto;
        text-align: right;
        a{
            color: #ffc900;
            text-decoration: none;
        }
    }

    &-inner {
        // width: 640px;
        width: 30%;
        height: 100vh;
        background: url(../assets/images/about-bg.jpg) no-repeat center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        padding: 204px 80px 80px 80px;
    }

    &-title {
        color: @yellowColor;
        margin-bottom: 60px;
        font: 700 42px/1.52 @secondFontFamily;
        letter-spacing: -0.29px;
    }

    &-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.5;
        color: #fff;
        margin-bottom: 10px;
        text-align: left;
        letter-spacing: -0.13px;
    }

    &-right {
        // max-width: 1200px;
        width: 70%;
        padding: 256px 80px 80px 140px;
    }

    &-img {
        margin-bottom: 175px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.footer-logo {
    display: block;
    text-align: right;
}

.social-links {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;

    &__item {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all 0.3s ease-in;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            fill: #FFC900;
        }

        &:hover {
            // transform: scale(1.1);
            background: #FFC900;
            box-shadow: 0px 24px 24px -14px rgba(249, 198, 35, 0.35);

            svg {
                fill: #fff;
            }
        }
    }
}

@media screen and (max-width: 1920px) {
    .about {
        &-inner {
            width: 640px;
        }

        &-right {
            max-width: 1200px;
            padding: 256px 80px 80px 140px;
        }
    }
}

@media screen and (max-width: 1440px) {
    .about-right {
        max-width: 70%;
        padding: 120px 40px 40px 40px;
    }

    .about-inner {
        padding: 40px;
    }

    .about-text {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
    }
}

@media screen and (max-height: 700px) {
    .about-right {
        padding-top: 60px;
    }

    .about-img {
        margin-bottom: 10%;
    }

    .about-title {
        margin-bottom: 30px;
    }
}


@media screen and (max-width: 1100px) {
    .about {
        overflow: auto;
        height: 100% !important;

        &-title {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: -0.21px;
            margin-bottom: 20px;
        }

        &-wrapper {
            flex-wrap: wrap;
        }

        &-inner {
            height: 100%;
            width: 100%;
            padding: 50px 20px;
        }

        &-right {
            display: none;
        }

        &-text {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.098px;
        }

        .social-links {
            display: none;
        }

        .about-text2 {
            margin-bottom: 0;
        }
    }

    .fp-tableCell {
        height: auto !important;
    }
}

@media screen and (max-width: 480px) {
    .about {
        &-inner {
            height: 100%;
            padding: 20px;
        }
    }
}
</style>

<style>
@media screen and (max-width: 1100px) {
    .fp-tableCell {
        height: auto !important;
    }

}
</style>
