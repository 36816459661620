<template>
  <hooper
    style="width: 100%; height: 100vh"
    class="s-slider2"
    :wheelControl="false"
    :infiniteScroll="true"
    ref="carousel"
    @slide="updateCarousel"
  >
    <slide>
      <img src="../assets/images/slide6.png" alt="slide" />
    </slide>
    <slide>
      <img src="../assets/images/slide7.png" alt="slide" />
    </slide>
    <slide>
      <img src="../assets/images/slide1.jpeg" alt="slide" />
    </slide>
    <slide>
      <img src="../assets/images/slide2.jpeg" alt="slide" />
    </slide>
    <slide>
      <img src="../assets/images/slide3.jpeg" alt="slide" />
    </slide>
     <!-- <hooper-pagination slot="hooper-addons" mode="fraction"></hooper-pagination> -->
  </hooper>
</template>

<script>
import {
  Hooper,
  Slide,
  Pagination as HooperPagination
} from 'hooper';

import "hooper/dist/hooper.css";

export default {
  name: "App",
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  data() {
    return {
      carouselData: 0,
    };
  },
  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    },
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    updateCarousel(payload) {
      this.myCarouselData = payload.currentSlide;
    },
  },
};
</script>

<style lang="less" scoped>
.s-slider2 .hooper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.hooper-slide {
  outline: none !important;
}
</style>

<style>
.hooper-next:focus {
  outline: none !important;
}
.hooper-prev:focus {
  outline: none !important;
}
.hooper:focus {
  outline: none !important;
}

@media screen and (max-width: 1100px){
  .hooper-track {
    display: flex;
    align-items: center;
  }
  .hooper {
    height: auto !important;
  }
}
</style>
